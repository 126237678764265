export default {
    methods: {
        getAllCursos(){
            this.$http
            .get(`${this.$apiUrl}abogado-ciudadano/cursos`)
            .then(response => {
              this.cursos = response.data.data.items
              this.$store.commit('curso/setCursos',this.cursos);
              this.getMisCrusos()
            })
        },
        getMisCrusos(){
          this.$http
          .get(`${this.$apiUrl}abogado-ciudadano/registro-curso`)
          .then(response => {
            this.misCrusos = response.data.data
            this.$store.commit('curso/setRegistrosCursos',this.misCrusos);
          })
        },
        getFileUrl(url){
            return this.$docUrl+'/'+url;
        },
        getExpositores(data) {
            return JSON.parse(data);
        },
        validarTiempo(date, hora) {
          var hoy = new Date();
          const [dates, hours] = date.split(" ");
          var fechaFormulario = new Date(dates + " " + hora);
          if (hoy <= fechaFormulario) {
            return true;
          } else {
            return false;
          }
        }
    }
}