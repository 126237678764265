<template>
    <v-flex lg12 xs12>
       <v-layout row wrap >
            {{cantidadCursosVisibles}}
          <v-carousel 
            v-if="itemsCarusel.length>0"
            hide-delimiters
            light
            height="auto"
            :interval="5000"
            cycle
          >
           <v-carousel-item
            v-for="(item,i) in itemsCarusel"
            :key="i"
            >
            <v-layout row wrap>
                <v-flex d-flex lg3 md4 sm6 xs12 v-for="(curso,j) in item" :key="j">
                    <v-card color="blue lighten-5" class="elevation-10" :class="$vuetify.breakpoint.xs?'mx-5':'mx-2 mb-2'" width="100%" @click="registrarCurso(curso)">
                    <v-img :src="getFileUrl(curso.foto)" :aspect-ratio="$vuetify.breakpoint.XS?'3':'1.5'"></v-img>
                    <v-card-text>
                        <v-layout row wrap>
                            <v-flex xs12  class="subheading font-weight-bold black--text">
                                 "{{curso.tema}}"
                            </v-flex>
                            <v-flex xs12>
                                 <v-chip :color="getColorTipoCurso(curso.tipo)" dark small class="mr-1">{{curso.tipo}}</v-chip>
                                 <v-chip v-show="curso.nombreDepartamento" color="grey" dark small class="mr-1" >{{curso.nombreDepartamento}}</v-chip>
                                 <v-chip v-show="validarTiempo(curso.fechaEvento,curso.horaInicio)" color="error" dark small class="mr-1">NUEVO</v-chip>
                            </v-flex>
                            <v-flex xs12 class="black--text">
                                  Fecha Evento: {{ formatDateText(curso.fechaEvento) }}
                            </v-flex>
                        </v-layout>                  
                    </v-card-text>             
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                        text
                        color="primary"
                        @click.native="registrarCurso(curso)"
                        >Mas Información</v-btn>
                    </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
            </v-carousel-item>
         </v-carousel>  
          <v-flex lg12>
              <v-card>
                  <v-toolbar color="grey lighten-3" class=" elevation-0">
                        <div class="text-h6 font-weight-bold py-3">Mis Registros </div>
                  </v-toolbar>
                  <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="misCrusos"
                            class="elevation-0"
                            mobile-breakpoint="100"
                        >
                            <template v-slot:item.actions="{item}">
                                <v-icon @click="showRegistroCurso(item)">mdi-information</v-icon>
                            </template>
                            <template v-slot:item.fechaEvento="{item}">
                                {{formatDateText(item.fechaEvento)}}
                            </template>
                            <template v-slot:item.fechaRegistro="{item}">
                                {{formatDateText(item.fechaRegistro)}}
                            </template>  
                        </v-data-table>
                  </v-card-text>
              </v-card>
            
            
          </v-flex>
        </v-layout>
        <Curso 
            v-if="dialog"
            :dialog="dialog"
            :idCurso="idCurso"
            :idRegistroCurso="idRegistroCurso"
            :modelo="modeloSelecionado"
            @cerrar="dialog=false"
        />
    </v-flex>
</template>
<script>
import mixinDateTime from "@/mixins/datetime"
import mixinCurso from "@/mixins/curso";
export default {
    mixins:[mixinCurso,mixinDateTime],
    components:{
        'Curso':()=>import("@/views/Curso/Curso")
    },
    data(){
        return{
            cursos:[],
            itemsCarusel:[],
            headers:[
                {
                    text: "ACCIONES",
                    align: "left",
                    value: "actions",
                    sortable: false,
                    width: "100px"
                },
                {
                    text: "TIPO",
                    align: "left",
                    value: "tipo",
                    sortable: false,
                    width: "100px"
                },
                {
                    text: "TEMA",
                    value: "tema",
                    sortable: false,
                    width: "150px"
                },
                {
                    text: "FECHA",
                    value: "fechaEvento",
                    sortable: false,
                    width: "150px"
                },
                {
                    text: "FECHA REGISTRO",
                    value: "fechaRegistro",
                    sortable: false,
                    width: "200px"
                }
            ],
            misCrusos:[],
            dialog:false,
            idCurso:-1,
            idRegistroCurso:-1,
            modeloSelecionado:null
        }
    },
    computed:{
        cantidadCursosVisibles(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': {
                    this.getItemsCarousel(1)
                    return 1
                }
                case 'sm': {
                    this.getItemsCarousel(2)
                    return 2
                }
                case 'md': {
                    this.getItemsCarousel(3)
                    return 3
                }
                case 'lg': {
                    this.getItemsCarousel(4)
                    return 4
                }
                case 'xl': {
                    this.getItemsCarousel(4)
                    return 4
                }

            }
        },
    },
    created(){
      this.getAllCursos()
    },
    methods:{
        getColorTipoCurso(tipo){
            return tipo=='VIRTUAL'?'grey':'primary'
        },
        getItemsCarousel(cant){
            if(this.cursos.length){
                this.itemsCarusel=[]
                var indice=0
                for (let index = 1; index<=this.cursos.length/cant; index++) {
                    this.itemsCarusel.push(this.cursos.slice(indice,parseFloat(indice)+parseFloat(cant)))
                    indice=parseFloat(indice)+parseFloat(cant)
                }
            }
        },
        registrarCurso(curso){
            this.dialog=true
            this.idCurso=curso.id
            this.idRegistroCurso=-1
            this.modeloSelecionado=curso
        },
        showRegistroCurso(registroCurso){
            this.dialog=true
            this.idCurso=-1
            this.idRegistroCurso=registroCurso.id
            this.modeloSelecionado=registroCurso
        }
    }
}
</script>